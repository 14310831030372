/** 
 *   礼品专区
 */
import { get } from '@/untils/js/axios.js'

// 获取礼品专区分类
export const getTypeList = id => get(`/special/type-list/${id}`)

// 获取当前激活的礼品分类
export const getActiveType = params => get('/special/tagging-goods-list', params)

// 获取为你推荐
export const getRemGoodsList = (id,params) => get(`/goods/recommend/${id}`,params)

// 获取分类下商品
export const getTypeGoodsList = params => get('/special/goods-list', params)

